import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { WrapMode } from '../../lib/wrap';
import Gallery from '../gallery';
import GalleryItem from '../gallery/gallery-item';
import { GalleryItemIcon } from '../gallery/gallery-item/gallery-item.model';
import Modal from '../modal';
import Section from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import SectionTitle, { SectionTitlePosition } from '../section/section-title';
import style from '../work/style.scss';
import photoData, { PhotoData } from './data';

type PhotoPreview = PhotoData | undefined;

interface PreviewProps {
	item: PhotoPreview;
	onClose: () => void;
}

const Photos = () => {
	const [preview, setPreview] = useState<PhotoPreview>(undefined);

	return (
		<Section id="photos" wrap={WrapMode.None}>
			<div className={classNames(WrapMode.Wrap, style.intro)}>
				<SectionTitle position={SectionTitlePosition.Right}>
					Gallery
				</SectionTitle>

				<SectionSubtitle decoration={SubtitleDecoration.Corner}>
					Exploring our studio and our masterful creations
				</SectionSubtitle>
			</div>

			<Gallery>
				{photoData.map((photo, index) => (
					<GalleryItem
						key={index}
						icon={GalleryItemIcon.Photo}
						image={photo.thumb}
						label={photo.name}
						onSelect={() => setPreview(photo)}
					/>
				))}
			</Gallery>

			<Preview item={preview} onClose={() => setPreview(undefined)} />
		</Section>
	);
};

const Preview: FunctionComponent<PreviewProps> = ({ item, onClose }) => {
	if (!item) return null;

	return (
		<Modal onClose={onClose}>
			<img src={item.full} alt={item.name} />
		</Modal>
	);
};

export default Photos;
