import { FunctionComponent, h } from 'preact';
import { useEffect } from 'preact/hooks';
import closeIcon from './close.svg';
import style from './style.scss';

interface ModalProps {
	onClose: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({ onClose, children }) => {
	useEffect(() => {
		const body = document.body;
		body.style.overflow = 'hidden';
		return () => body.style.removeProperty('overflow');
	});

	useEffect(() => {
		const onEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') onClose();
		};

		window.addEventListener('keydown', onEscape, { once: true });
		return () => window.removeEventListener('keydown', onEscape);
	});

	function onModalClick(event: MouseEvent) {
		if (event.target === event.currentTarget) onClose();
	}

	return (
		<div className={style.modal} onClick={onModalClick}>
			{children}

			<button className={style.close} onClick={onClose}>
				<img src={closeIcon} alt="Close" />
			</button>
		</div>
	);
};

export default Modal;
