import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { WrapMode } from '../../lib/wrap';
import style from './style.scss';

const stylePrev = classNames(style.button, style.buttonPrev);
const styleNext = classNames(style.button, style.buttonNext);
const styleMenu = classNames(style.menu, WrapMode.Wrap);

function getCurrentItem(slider: HTMLElement): HTMLElement | undefined {
	// Find currently fully visible element
	const items = Array.from(slider.children) as HTMLElement[];
	const sliderPos = slider.scrollLeft;
	const sliderWidth = slider.clientWidth;

	return items.find((item) => {
		if (item.classList.contains(style.itemClone)) return false;

		const itemLeft = item.offsetLeft;
		const itemRight = itemLeft + item.clientWidth;
		return itemLeft >= sliderPos && itemRight <= sliderPos + sliderWidth;
	});
}

const Gallery: FunctionComponent = ({ children }) => {
	const sliderRef = useRef<HTMLUListElement>(null);

	function next() {
		const slider = sliderRef.current;

		// Set scroll position to the next item
		const currentItem = getCurrentItem(slider);
		if (!currentItem) return;

		const nextItem = currentItem.nextElementSibling as HTMLElement;
		if (!nextItem || nextItem.classList.contains(style.itemClone)) {
			slider.scrollTo({ left: 0, behavior: 'smooth' });
		} else {
			slider.scrollBy({ left: currentItem.offsetWidth, behavior: 'smooth' });
		}
	}

	function prev() {
		const slider = sliderRef.current;

		// Set scroll position to the previous item
		const currentItem = getCurrentItem(slider);
		if (!currentItem) return;

		const prevItem = currentItem.previousElementSibling as HTMLElement;
		if (!prevItem || prevItem.classList.contains(style.itemClone)) {
			slider.scrollTo({ left: slider.scrollWidth, behavior: 'smooth' });
		} else {
			slider.scrollBy({ left: -currentItem.offsetWidth, behavior: 'smooth' });
		}
	}

	useEffect(() => {
		// Clone first and last slider child and append / prepend it to the slider
		const slider = sliderRef.current;
		const firstItem = slider.firstElementChild as HTMLElement;
		const lastItem = slider.lastElementChild as HTMLElement;

		const cloneFirst = firstItem.cloneNode(true) as HTMLElement;
		cloneFirst.classList.add(style.itemClone, style.itemCloneRight);

		const cloneLast = lastItem.cloneNode(true) as HTMLElement;
		cloneLast.classList.add(style.itemClone, style.itemCloneLeft);

		slider.append(cloneFirst);
		slider.prepend(cloneLast);

		const updateCloneFirstPosition = () => {
			const translate =
				slider.scrollWidth - slider.offsetWidth + firstItem.offsetWidth;
			cloneFirst.style.transform = `translateX(${translate}px)`;
		};

		updateCloneFirstPosition();
		window.addEventListener('resize', updateCloneFirstPosition);
		return () => window.removeEventListener('resize', updateCloneFirstPosition);
	});

	return (
		<div className={style.sliderRoot}>
			<div className={style.sliderContainer}>
				<ul ref={sliderRef} className={style.slider}>
					{children}
				</ul>

				<button onClick={prev} className={stylePrev} aria-label="Previous">
					Previous
				</button>

				<button onClick={next} className={styleNext} aria-label="Next">
					Next
				</button>
			</div>

			<menu className={styleMenu}>
				<button onClick={prev} className={stylePrev} aria-label="Previous">
					Previous
				</button>

				<button onClick={next} className={styleNext} aria-label="Next">
					Next
				</button>
			</menu>
		</div>
	);
};

export default Gallery;
