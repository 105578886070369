import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import style from './style.scss';

export const enum SubtitleDecoration {
	None = '',
	Bottom = 'bottom',
	Corner = 'corner',
}

interface SectionSubtitleProps {
	decoration?: SubtitleDecoration;
}

const SectionSubtitle: FunctionComponent<SectionSubtitleProps> = ({
	children,
	decoration = SubtitleDecoration.None,
}) => (
	<h2 className={classNames(style.subtitle, style[decoration])}>{children}</h2>
);

export default SectionSubtitle;
