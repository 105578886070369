import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import style from './style.scss';

export const enum SectionTitlePosition {
	Left = 'left',
	Right = 'right',
}

interface SectionTitleProps {
	position?: SectionTitlePosition;
}

const SectionTitle: FunctionComponent<SectionTitleProps> = ({
	children,
	position,
}) => (
	<h1 className={classNames(style.title, style[position])}>
		<span className={style.inner}>{children}</span>
	</h1>
);

export default SectionTitle;
