import { h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Section, { SectionStyle } from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import SectionTitle from '../section/section-title';
import Text from '../text';
import actual from './images/actual.jpg';
import virtual from './images/virtual.jpg';
import style from './style.scss';

const About = () => (
	<Section id="about" wrap={WrapMode.Wrap} style={SectionStyle.White}>
		<SectionTitle>About us</SectionTitle>

		<div className={style.content}>
			<div className={style.text}>
				<SectionSubtitle decoration={SubtitleDecoration.Bottom}>
					We are a full service motion capture studio
				</SectionSubtitle>

				<Text>
					We offer a range of services for advertising, TV and gaming
					industries. <strong>light lab LTD</strong> was founded in Riga, Latvia
					in 2012 by a small team of industry professionals, and, since then, we
					have participated in the production of more than
					150&nbsp;advertisements and 3D animated videos.
				</Text>

				<Text>
					Having clients such as European Central Bank, Bank of Latvia and
					Deutsche Welle, creating TV series Zapovednik for DW — we are here to
					provide high end production services to you in accordance with your
					scale and needs.
				</Text>
			</div>

			<Images />
		</div>
	</Section>
);

const Images = () => {
	const images = [virtual, actual] as const;

	return (
		<ul className={style.images}>
			{images.map((image, index) => (
				<li className={style.image} key={index}>
					<img src={image} alt="" width={793} height={907} />
				</li>
			))}
		</ul>
	);
};

export default About;
