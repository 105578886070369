import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { WrapMode } from '../../lib/wrap';
import Embed from '../embed';
import Gallery from '../gallery';
import GalleryItem from '../gallery/gallery-item';
import { GalleryItemIcon } from '../gallery/gallery-item/gallery-item.model';
import Modal from '../modal';
import Section from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import SectionTitle, { SectionTitlePosition } from '../section/section-title';
import Text from '../text';
import style from './style.scss';
import data from './work.json';
import { WorkItem } from './work.model';

type WorkPreview = WorkItem | undefined;

interface PreviewProps {
	item: WorkPreview;
	onClose: () => void;
}

const workData = data as WorkItem[];

const Work = () => {
	const [preview, setPreview] = useState<WorkPreview>(undefined);
	const youtube = 'https://www.youtube.com/c/zapovednik/videos';

	return (
		<Section id="work" className={style.section} wrap={WrapMode.None}>
			<div className={classNames(WrapMode.Wrap, style.intro)}>
				<SectionTitle position={SectionTitlePosition.Right}>
					Our work
				</SectionTitle>

				<SectionSubtitle decoration={SubtitleDecoration.Corner}>
					Our latest projects
				</SectionSubtitle>

				<Text className={style.text}>
					Since 2017 we are creating <a href={youtube}>Zapovednik</a>, an
					animated series of political satire for Deutsche Welle, which has
					reached more than 2.5&nbsp;million subscribers and more than
					250&nbsp;million views on Youtube, Facebook and TikTok.
				</Text>
			</div>

			<Gallery>
				{workData.map((item, index) => (
					<GalleryItem
						key={index}
						icon={GalleryItemIcon.Video}
						image={item.thumbnail}
						label={item.title}
						onSelect={() => setPreview(item)}
					/>
				))}
			</Gallery>

			<Preview item={preview} onClose={() => setPreview(undefined)} />
		</Section>
	);
};

const Preview: FunctionComponent<PreviewProps> = ({ item, onClose }) => {
	if (!item) return null;

	return (
		<Modal onClose={onClose}>
			<Embed id={item.id} />
		</Modal>
	);
};

export default Work;
