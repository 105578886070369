import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import style from './style.scss';

interface TextProps {
	className?: string;
}

const Text: FunctionComponent<TextProps> = ({ children, className }) => (
	<p className={classNames(style.text, className)}>{children}</p>
);

export default Text;
