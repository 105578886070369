import classNames from 'classnames';
import { h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import style from './style.scss';

const wrapClass = classNames(WrapMode.Wrap, style.wrap);

const links = [
	{ target: 'about', label: 'About us' },
	{ target: 'work', label: 'Our work' },
	{ target: 'prices', label: 'Prices' },
	{ target: 'photos', label: 'Gallery' },
	{ target: 'contacts', label: 'Contacts' },
];

const Header = () => (
	<header className={style.header}>
		<div className={wrapClass}>
			<nav className={style.nav}>
				<h1 className={style.logoContainer}>
					<a href="/" className={style.logo} onClick={onLogoClick}>
						Light lab
					</a>
				</h1>

				<ul className={style.links}>
					{links.map((link, index) => (
						<NavLink key={index} target={link.target} label={link.label} />
					))}
				</ul>
			</nav>
		</div>
	</header>
);

const NavLink = ({ target, label }) => (
	<li>
		<a href={`#${target}`} onClick={onNavLinkClick}>
			{label}
		</a>
	</li>
);

function onLogoClick(e) {
	e.preventDefault();
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

function onNavLinkClick(e) {
	e.preventDefault();

	const target = e.target.getAttribute('href');
	const element = document.querySelector<HTMLElement>(target);
	element.scrollIntoView({ behavior: 'smooth' });
}

export default Header;
