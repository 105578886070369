import { h } from 'preact';

import About from './about';
import Contacts from './contacts';
import Footer from './footer';
import Header from './header';
import Intro from './intro';
import Main from './main';
import Photos from './photos';
import Prices from './prices';
import Showcase from './showcase';
import Studio from './studio';
import Work from './work';

const App = () => (
	<div id="app">
		<Header />
		<Main>
			<Intro />
			<About />
			<Showcase />
			<Studio />
			<Work />
			<Prices />
			<Photos />
			<Contacts />
		</Main>
		<Footer />
	</div>
);

export default App;
