import photoData from './photos.json';

if (!photoData) throw 'No photo data found';
if (!Array.isArray(photoData)) throw 'Photo data is not an array';

export interface PhotoData {
	name: string;
	full: string;
	thumb: string;
}

function toAssetPath(path: string): string {
	const prefix = '/assets/photos/';
	return path.replace('./', prefix);
}

const PhotoData: PhotoData[] = photoData.map((data: PhotoData) => ({
	...data,
	full: toAssetPath(data.full),
	thumb: toAssetPath(data.thumb),
}));

export default PhotoData;
