import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Section, { SectionStyle } from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import cardIcon from './icons/address-card.svg';
import mailIcon from './icons/envelope.svg';
import locationIcon from './icons/location-dot.svg';
import phoneIcon from './icons/phone.svg';
import map from './map.jpg';
import style from './style.scss';

const Contacts = () => (
	<Section id="contacts" style={SectionStyle.White} wrap={WrapMode.Wrap}>
		<SectionSubtitle decoration={SubtitleDecoration.Corner}>
			Contact us
		</SectionSubtitle>

		<div className={style.content}>
			<div className={style.contacts}>
				<h3 className={style.subtitle}>Info</h3>

				<dl>
					<Contact icon={cardIcon} title="Contact">
						Liga Andersone, CEO
					</Contact>

					<Contact icon={mailIcon} title="Email">
						<a href="mailto:liga@lightlab.lv">liga@lightlab.lv</a>
					</Contact>

					<Contact icon={phoneIcon} title="Phone">
						<a href="tel:+37126669922">+371 26669922</a>
					</Contact>

					<Contact icon={locationIcon} title="Location">
						<a
							href="https://goo.gl/maps/ecnmUB3cnBKS2yy76"
							target="_blank"
							rel="noreferrer"
						>
							Riga, Latvia
						</a>
					</Contact>
				</dl>
			</div>

			<div className={style.map}>
				<img src={map} alt="Map" loading="lazy" />
			</div>
		</div>
	</Section>
);

const Contact: FunctionComponent<{ icon: string; title: string }> = ({
	children,
	icon,
	title,
}) => (
	<div className={style.contact}>
		<dt>
			<img src={icon} alt={title} title={title} />
		</dt>
		<dd>{children}</dd>
	</div>
);

export default Contacts;
