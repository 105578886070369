import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import sectionStyle from './style.scss';

export const enum SectionStyle {
	Transparent = 'transparent',
	White = 'white',
}

interface SectionProps {
	id?: string;
	className?: string;
	contentClassName?: string;
	wrap?: WrapMode;
	style?: SectionStyle;
}

// Use the same backgrounds bag for all sections
const backgrounds = getSessionBackgrounds();

// Keep track of the last section index to use for the background
let lastSectionIndex = 0;

const Section: FunctionComponent<SectionProps> = (props) => {
	const {
		children,
		id,
		className,
		contentClassName,
		wrap = WrapMode.Wrap,
		style = SectionStyle.Transparent,
	} = props;

	const sectionIndex = lastSectionIndex++;
	const background = backgrounds[sectionIndex % backgrounds.length];

	const sectionClass = classNames(
		sectionStyle.section,
		sectionStyle[style],
		className,
	);

	const innerClass = classNames(wrap, contentClassName);

	return (
		<section id={id} className={sectionClass}>
			<div className={innerClass}>{children}</div>

			{style === SectionStyle.Transparent && (
				<div
					className={sectionStyle.background}
					style={{ backgroundImage: `url("${background}")` }}
				/>
			)}
		</section>
	);
};

function getSessionBackgrounds(): string[] {
	const backgroundsCount = 9;

	return Array.from(Array(backgroundsCount).keys())
		.sort(() => Math.random() - 0.5)
		.map((index) => `/assets/backgrounds/${index}-poster.jpg`);
}

export default Section;
