import classNames from 'classnames';
import { Fragment, FunctionComponent, h } from 'preact';
import style from './style.scss';

interface ProductProps {
	className?: string;
	type: string;
	duration?: string;
	price?: number;
	details?: string[];
}

const Product: FunctionComponent<ProductProps> = ({
	className,
	type,
	duration,
	price,
	details,
}) => (
	<li className={classNames(style.container, className)}>
		<h3 className={style.type}>{type}</h3>

		<Duration>{duration}</Duration>
		<Price>{price}</Price>
		<Details>{details}</Details>
		<Action>{price ? 'Get started' : 'Contact us'}</Action>
	</li>
);

const Duration: FunctionComponent = ({ children }) =>
	children ? <p className={style.duration}>{children}</p> : null;

const Price: FunctionComponent = ({ children }) => {
	const content = children ? (
		<>
			€<strong>{children}</strong> <small>+ VAT</small>
		</>
	) : (
		'Contact us to get a customized offer'
	);

	return <p className={style.price}>{content}</p>;
};

const Details: FunctionComponent<{ children: string[] }> = ({ children }) => {
	if (!children || !children.length) return null;

	return (
		<ul className={style.details}>
			{children.map((detail, i) => (
				<li key={i}>{detail}</li>
			))}
		</ul>
	);
};

const Action: FunctionComponent = ({ children }) => {
	const onClick = (e) => {
		e.preventDefault();

		const target = e.target.getAttribute('href');
		const element = document.querySelector<HTMLElement>(target);
		element.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div className={style.action}>
			<a href="#contacts" onClick={onClick}>
				{children}
			</a>
		</div>
	);
};

export default Product;
