import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Embed from '../embed';
import Section from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import style from './style.scss';

const Showcase: FunctionComponent = () => (
	<Section id="showcase" wrap={WrapMode.Wide}>
		<SectionSubtitle decoration={SubtitleDecoration.Corner}>
			Unleash your imagination: Captivating animated videos that spark emotion
		</SectionSubtitle>

		<Embed id="818137196" className={style.embed} />
	</Section>
);

export default Showcase;
