import classNames from 'classnames';
import { createRef, FunctionComponent, h } from 'preact';
import { GalleryItemIcon } from './gallery-item.model';
import photoIcon from './icons/photo.svg';
import videoIcon from './icons/video.svg';
import style from './style.scss';

interface GalleryItemProps {
	icon?: GalleryItemIcon;
	image: string;
	label: string;
	onSelect?: () => void;
}

const icons: Record<GalleryItemIcon, string> = {
	photo: photoIcon,
	video: videoIcon,
};

const GalleryItem: FunctionComponent<GalleryItemProps> = (props) => {
	const { icon, image, label, onSelect } = props;

	const imgRef = createRef<HTMLImageElement>();
	const onImgError = () => imgRef.current.remove();

	return (
		<li className={style.item}>
			<button
				className={style.thumbnail}
				aria-label={label}
				onClick={() => onSelect?.()}
			>
				<img
					className={style.image}
					src={image}
					alt={label}
					loading="lazy"
					ref={imgRef}
					onError={onImgError}
				/>

				<Icon icon={icon} />
			</button>
		</li>
	);
};

const Icon: FunctionComponent<{ icon?: GalleryItemIcon }> = ({ icon }) => {
	if (!icon) return null;

	const src = icons[icon];
	const className = classNames(style.icon, style[`icon-${icon}`]);

	return <img src={src} className={className} alt="" />;
};

export default GalleryItem;
