import { h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Section, { SectionStyle } from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import SectionTitle, { SectionTitlePosition } from '../section/section-title';
import Product from './product';
import style from './style.scss';

const Prices = () => (
	<Section id="prices" style={SectionStyle.White} wrap={WrapMode.Wrap}>
		<SectionTitle position={SectionTitlePosition.Right}>Prices</SectionTitle>

		<div className={style.subsection}>
			<SectionSubtitle decoration={SubtitleDecoration.Corner}>
				Prices for studio rental
			</SectionSubtitle>

			<ul className={style.group}>
				<Product
					type="Half shift"
					duration="4 hours"
					price={1200}
					details={studioDetails}
				/>

				<Product
					type="Full shift"
					duration="8 hours"
					price={2000}
					details={studioDetails}
				/>

				<Product type="Other" className={style.noMobile} />
			</ul>
		</div>

		<div className={style.subsection}>
			<SectionSubtitle decoration={SubtitleDecoration.Corner}>
				Prices for actors
			</SectionSubtitle>

			<ul className={style.group}>
				<Product
					type="Mocap facial actor"
					duration="Full shift (8 hours)"
					price={500}
					details={actorDetailsFacial}
				/>

				<Product
					type="Mocap actor"
					duration="Full shift (8 hours)"
					price={1000}
					details={actorDetailsFull}
				/>

				<Product type="Other" />
			</ul>
		</div>
	</Section>
);

const studioDetails = [
	'Mocap Studio rental',
	'Mocap Operator',
	'Live-video monitoring',
];

const actorDetailsFacial = [
	'High-quality performances',
	'Wide range of actions and emotions',
	'Facial capture with data gloves',
];

const actorDetailsFull = [
	'High-quality performances, actions and emotions',
	'Finger capture',
	'Time and cost savings in production',
];

export default Prices;
