import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Section, { SectionStyle } from '../section';
import SectionSubtitle, { SubtitleDecoration } from '../section/section-subtitle';
import SectionTitle, { SectionTitlePosition } from '../section/section-title';
import Text from '../text';
import image from './image.jpg';
import style from './style.scss';

const Studio: FunctionComponent = () => (
	<Section wrap={WrapMode.Wrap} style={SectionStyle.White}>
		<SectionTitle position={SectionTitlePosition.Right}>About us</SectionTitle>

		<div className={style.content}>
			<div className={style.text}>
				<SectionSubtitle decoration={SubtitleDecoration.Bottom}>
					Cutting edge motion capture: empowering film pros with sensible
					pricing
				</SectionSubtitle>

				<Text>
					We offer the cutting edge motion capture technology with film industry
					pros and a sensible pricing. Our motion capture studio renders the
					full cycle of animation production faster and more accessible. With{' '}
					<strong>28&nbsp;Optitrack Prime13 cameras</strong> for capturing
					motion in great detail, using motive / Autodesk Motion Builder combo,
					created in the <strong>Unity</strong> game development engine, using
					costumes with 42 marks, <strong>StretchSense</strong> and{' '}
					<strong>5DT</strong> gloves for the recording of finger movement and
					character facial expressions, we provide everything needed for TV
					series, VR, gaming, art, and even more.
				</Text>

				<Text>
					Our studio’s services include in-house editing, colour grading, motion
					capture, and 3D services as well as local and international casting,
					and a sound studio. From pre- to postproduction, we have the
					equipment, the experience and the industry connections to help bring
					your vision to life.
				</Text>
			</div>

			<div className={style.image}>
				<img src={image} alt="" width={890} height={890} />
			</div>
		</div>
	</Section>
);

export default Studio;
