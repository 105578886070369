import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import facebookIcon from './icons/facebook.svg';
import linkedInIcon from './icons/linkedin.svg';
import vimeoIcon from './icons/vimeo.svg';
import style from './style.scss';

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<footer className={classNames(WrapMode.Wrap, style.footer)}>
			<p className={style.copyright}>© {year} Light Lab</p>

			<ul className={style.links}>
				<Link href="https://vimeo.com/lightlab" icon={vimeoIcon}>
					Vimeo
				</Link>

				<Link href="https://www.facebook.com/lightlab.lv" icon={facebookIcon}>
					Facebook
				</Link>

				<Link
					href="https://www.linkedin.com/company/light-lab-sia/"
					icon={linkedInIcon}
				>
					LinkedIn
				</Link>
			</ul>
		</footer>
	);
};

const Link: FunctionComponent<{ href: string; icon?: string }> = ({
	children,
	href,
	icon,
}) => {
	return (
		<li>
			<a href={href} target="_blank" rel="noreferrer">
				{icon ? <img src={icon} alt={children.toString()} /> : children}
			</a>
		</li>
	);
};

export default Footer;
