import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { isDev } from '../../lib/is-dev';
import style from './style.scss';

interface EmbedProps {
	id: string;
	className?: string;
	videoParams?: Record<string, string>;
}

const Embed: FunctionComponent<EmbedProps> = ({
	id,
	className,
	videoParams,
}) => {
	const embedClass = classNames(style.embed, className);

	const params = new URLSearchParams({
		h: 'a7c8cfc755', // Hash for accessing unlisted videos
		color: 'ffffff',
		title: '0',
		byline: '0',
		portrait: '0',
		dnt: '1',
		...videoParams,
	});

	const src = `https://player.vimeo.com/video/${id}?${params.toString()}`;

	if (isDev) {
		const devClass = classNames(embedClass, style.dev);
		return <div className={devClass} />;
	}

	return (
		<iframe
			className={embedClass}
			src={src}
			frameBorder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowFullScreen
		/>
	);
};

export default Embed;
