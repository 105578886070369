import classNames from 'classnames';
import { FunctionComponent, h } from 'preact';
import { WrapMode } from '../../lib/wrap';
import Section from '../section';
import style from './style.scss';

const Intro = () => {
	return (
		<Section className={style.intro} wrap={WrapMode.None}>
			<div className={classNames(style.content, WrapMode.Wrap)}>
				<h1 className={style.title}>
					Create a{' '}
					<em>
						14&nbsp;<abbr title="minute">min</abbr>
					</em>{' '}
					animated episode in&nbsp;just 4&nbsp;days!
				</h1>

				<p className={style.subtitle}>
					We are a full service motion capture studio offering a range of
					services for advertising, TV and gaming industries.
				</p>

				<div className={style.links}>
					<IntroLink primary={true} anchor="prices">
						Get started
					</IntroLink>
					<IntroLink anchor="showcase">Watch video</IntroLink>
				</div>
			</div>
		</Section>
	);
};

interface IntroLinkProps {
	primary?: boolean;
	anchor?: string;
}

const IntroLink: FunctionComponent<IntroLinkProps> = ({
	anchor,
	children,
	primary,
}) => {
	const className = classNames(style.link, {
		[style.primary]: primary,
		[style.secondary]: !primary,
	});

	const onClick = (e: MouseEvent) => {
		e.preventDefault();

		if (anchor) {
			const element = document.getElementById(anchor);
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<a
			className={className}
			href={anchor ? `#${anchor}` : undefined}
			onClick={onClick}
		>
			{children}
		</a>
	);
};

export default Intro;
